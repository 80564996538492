<!--
 * @Author: tjessie
 * @Date: 2022-02-10 13:36:09
 * @LastEditors: tjessie
 * @LastEditTime: 2022-02-10 13:36:36
 * @Description: file content
 * @FilePath: \juqu-skypegmwcn\src\views\pc\examine.vue
-->
<template>
	<div>
		<div class="content">
			<div class="title">发布信息</div>
			<el-form
				:model="saveReleaseForm"
				:rules="saveReleaseFormRules"
				ref="saveReleaseFormRef"
				label-width="100px"
			>
				<el-form-item label="全职招聘" prop="account">
					<el-select v-model="teacherTypeValue">
						<el-option
							v-for="item in teacherTypeList"
							:key="item.id"
							:label="item.typeName"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="公司名称"
					prop="companyName"
					style="width:318px"
				>
					<el-input
						placeholder="请输入"
						maxlength="50"
						v-model="saveReleaseForm.companyName"
					></el-input>
				</el-form-item>
				<el-form-item label="工作地区" prop="region">
					<!-- <el-cascader
						size="large"
						:options="options"
						v-model="selectedOptions"
						@change="handleChange"
					>
					</el-cascader> -->
					<el-select v-model="regionTypeValue">
						<el-option
							v-for="item in regionTypeList"
							:key="item.id"
							:label="item.typeName"
							:value="item.typeName"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="有效期" prop="validity">
					<el-select v-model="validityTypeValue">
						<el-option
							v-for="item in validityTypeList"
							:key="item.id"
							:label="item.typeName"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="信息标题" prop="title" style="width:318px">
					<el-input
						placeholder="请输入"
						maxlength="50"
						v-model="saveReleaseForm.title"
					></el-input>
				</el-form-item>
				<el-form-item label="月薪" prop="salary">
					<el-select v-model="salaryTypeValue">
						<el-option
							v-for="item in salaryTypeList"
							:key="item.id"
							:label="item.typeName"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="学历要求" prop="education">
					<el-select v-model="educationTypeValue">
						<el-option
							v-for="item in educationTypeList"
							:key="item.id"
							:label="item.typeName"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作年限" prop="years" style="width:318px">
					<el-input
						placeholder="请输入"
						maxlength="2"
						oninput="value=value.replace(/[^\d]/g,'')"
						v-model="saveReleaseForm.years"
					></el-input>
				</el-form-item>
				<el-form-item
					label="联系电话"
					prop="tellPhone"
					style="width:318px"
				>
					<el-input
						placeholder="请输入"
						maxlength="11"
						oninput="value=value.replace(/[^\d]/g,'')"
						v-model="saveReleaseForm.tellPhone"
					></el-input>
				</el-form-item>
				<el-form-item
					label="岗位职责"
					prop="responsibilities"
					style="width:642px"
				>
					<el-input
						type="textarea"
						placeholder="请输入"
						maxlength="400"
						show-word-limit
						v-model="saveReleaseForm.responsibilities"
					></el-input>
				</el-form-item>
				<el-form-item
					label="任职要求"
					prop="requirements"
					style="width:642px"
				>
					<el-input
						type="textarea"
						placeholder="请输入"
						maxlength="400"
						show-word-limit
						v-model="saveReleaseForm.requirements"
					></el-input>
				</el-form-item>
				<el-form-item>
					<div class="submit" @click="insertIssue">确认提交</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
const axios = require('axios')
export default {
	components: {},
	data() {
		return {
			options: regionData,
			selectedOptions: [],
			saveReleaseForm: {},
			// 添加表单的验证规则对象
			saveReleaseFormRules: {
				account: [
					{
						required: true,
						message: '请输入活动标题',
						trigger: 'blur'
					}
				],
				companyName: [
					{
						required: true,
						message: '请输入活动内容',
						trigger: 'blur'
					}
				],
				region: [
					{
						required: true,
						message: '请选择地区',
						trigger: 'blur'
					}
				],
				validity: [
					{
						required: true,
						message: '请选择有效期',
						trigger: 'blur'
					}
				],
				title: [
					{
						required: true,
						message: '请输入信息标题',
						trigger: 'blur'
					}
				],
				salary: [
					{
						required: true,
						message: '请选择月薪',
						trigger: 'blur'
					}
				],
				education: [
					{
						required: true,
						message: '请选择学历',
						trigger: 'blur'
					}
				],
				years: [
					{
						required: true,
						message: '请输入工作年限',
						trigger: 'blur'
					}
				],
				tellPhone: [
					{
						required: true,
						message: '请输入联系电话',
						trigger: 'blur'
					}
				],
				responsibilities: [
					{
						required: true,
						message: '请输入岗位职责',
						trigger: 'blur'
					}
				],
				requirements: [
					{
						required: true,
						message: '请输入任职要求',
						trigger: 'blur'
					}
				]
			},
			teacherTypeList: [
				{
					id: 0,
					typeName: '线上老师'
				},
				{
					id: 1,
					typeName: '线下老师'
				},
				{
					id: 2,
					typeName: '辅导老师'
				}
			],
			teacherTypeValue: '',
			regionTypeList: [
				{
					id: 1,
					typeName: '长沙'
				}
			],
			regionTypeValue: '',
			validityTypeList: [
				{
					id: 0,
					typeName: '长期有效'
				},
				{
					id: 1,
					typeName: '一周'
				},
				{
					id: 2,
					typeName: '一月'
				},
				{
					id: 3,
					typeName: '三月'
				},
				{
					id: 4,
					typeName: '半年'
				}
			],
			validityTypeValue: '',
			salaryTypeList: [
				{
					id: 0,
					typeName: '面议'
				},
				// {
				// 	id: 1,
				// 	typeName: '2000以下',
				// },
				{
					id: 1,
					typeName: '2000-4000'
				},
				{
					id: 2,
					typeName: '4000-8000'
				},
				{
					id: 3,
					typeName: '8000-12000'
				},
				{
					id: 4,
					typeName: '12000以上'
				}
			],
			salaryTypeValue: '',
			educationTypeList: [
				{
					id: 0,
					typeName: '不限'
				},
				{
					id: 1,
					typeName: '初中及以下'
				},
				{
					id: 2,
					typeName: '高中、中专'
				},
				{
					id: 3,
					typeName: '大专'
				},
				{
					id: 4,
					typeName: '本科'
				},
				{
					id: 5,
					typeName: '硕士'
				},
				{
					id: 6,
					typeName: '博士及以上'
				}
			],
			educationTypeValue: ''
		}
	},
	created: function() {},
	methods: {
		// 新增发布信息
		async insertIssue() {
			var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
			console.log(!reg_tel.test(this.saveReleaseForm.tellPhone))
			if (!reg_tel.test(this.saveReleaseForm.tellPhone)) {
				this.$message.error('请输入正确的手机号码')
				return
			}
			var value = ''
			switch (this.teacherTypeValue) {
				case 0:
					value = '线上老师'
					break
				case 1:
					value = '线下老师'
					break
				case 2:
					value = '辅导老师'
					break
			}
			var params = new URLSearchParams()
			params.append('address', this.regionTypeValue)
			params.append('companyName', this.saveReleaseForm.companyName)
			params.append('education', this.educationTypeValue)
			params.append('indate', this.validityTypeValue)
			params.append('issueType', this.teacherTypeValue)
			params.append('jobRequire', this.saveReleaseForm.requirements)
			params.append(
				'responsibility',
				this.saveReleaseForm.responsibilities
			)
			params.append('salary', this.salaryTypeValue)
			params.append('tell', this.saveReleaseForm.tellPhone)
			params.append('term', this.saveReleaseForm.years)
			params.append('title', this.saveReleaseForm.title)
			params.append('type', 0)
			axios
				.post('https://api.juquedu.com/admin/apply/insertIssue', params)
				.then((result) => {
					if (result.status == 1001) {
						this.$message.success('发布信息成功！')
						this.$router.push({
							path: '/examineMore',
							query: { title: value }
						})
					}
					if (result.status != 1001) {
						return this.$message.error('发布信息失败！')
					}
				})
		},
		handleChange() {
			var loc = ''
			for (let i = 0; i < this.selectedOptions.length; i++) {
				loc += CodeToText[this.selectedOptions[i]]
			}
			alert(loc)
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	width: 1200px;
	margin: 65px auto 100px;
	.title {
		font-size: 48px;
		font-weight: 800;
		color: #333333;
		margin-bottom: 36px;
	}
	/deep/ .el-input__inner {
		background-color: #f6f7f9;
		border: 1px solid #dcdfe6;
	}
	/deep/ .el-textarea__inner {
		height: 234px;
		background-color: #f6f7f9;
		border: 1px solid #dcdfe6;
	}
	/deep/ .el-textarea .el-input__count {
		background-color: transparent;
		bottom: -5px;
		right: 10px;
	}
	.submit {
		width: 211px;
		height: 54px;
		background: #ff293e;
		border-radius: 27px;
		font-size: 20px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
